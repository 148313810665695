<template>
    <div class="container">
        <b-modal title="Categories" id="modal" no-close-on-esc hide-footer @hidden="model={}">
            <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                    <div class="row">
                        <div class="col-md-12">
                            <validated-input name="Name" label="Name" v-model="model.name" :rules="rules.name"/>
                        </div>
                        <div class="col-md-12 form-group">
                            <b-form-file alternative name="Icon" label="Icon" v-model="model.icon_path"
                                         :rules="rules.icon_path"/>
                        </div>
                        <div class="col-md-12">
                            <b-button type="submit" variant="success">
                                Save
                            </b-button>
                        </div>

                    </div>
                </b-form>
            </validation-observer>
        </b-modal>

        <b-card class="mt-4">
            <b-card-title>
                <b-button variant="success" size="sm" class="float-right" @click="openModal">New Category</b-button>
                <h3>Categories</h3>
            </b-card-title>
            <vue-table :data="data" :fields="fields" :url="url" ref="table">
                <template #image="{rowData}">
                    <img :src="rowData.icon_path" style="max-width: 50px"
                         class="rounded-circle" alt="">
                </template>
                <template #action="{rowData}">
                    <b-button-group>
                        <b-button size="sm" variant="primary" @click="editItem(rowData)">Edit</b-button>
                        <b-button size="sm" variant="danger" @click="deleteItem(rowData)">Delete</b-button>
                    </b-button-group>
                </template>
            </vue-table>
        </b-card>
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'secure-axios';
import urls from '@data/urls';

export default {
    name: 'Categories',
    components: {},
    data () {
        return {
            model: {
                name: '',
                icon_path: null
            },
            rules: {
                name: {
                    required: true
                }
            },
            url: urls.categories.list,
            data: [],
            fields: [
                {
                    name: 'id',
                    title: 'ID',
                    sortField: 'id'
                },
                {
                    name: 'name',
                    title: 'Name',
                    sortField: 'name'
                },
                {
                    name: '__slot:image',
                    title: 'Image'
                },
                {
                    name: '__slot:action',
                    title: 'Action'
                }
            ]
        };
    },

    methods: {
        openModal () {
            this.$bvModal.show('modal');
        },

        setData (response) {
            this.$refs.table.refreshTable();
        },

        async onSubmit () {
            let url;
            if (this.model.id) {
                url = urls.categories.update;
            } else {
                url = urls.categories.create;
            }

            const response = await axios.form(url, this.model);
            if (response.data.error) {
                this.$refs.formValidator.setErrors(response.data.errors);
            } else {
                this.$refs.table.refreshTable();
                this.$bvModal.hide('modal');
            }
        },

        async editItem (rowData) {
            this.model = {
                id: rowData.id,
                name: rowData.name
            };
            this.openModal();
        },

        async deleteItem (rowData) {
            const confirm = await this.$bvModal.msgBoxConfirm('Are you sure ?');
            if (confirm) {
                const params = { id: rowData.id };
                const response = await axios.form(urls.categories.delete, params);
                this.setData(response);
            }
        }
    }
};

</script>
